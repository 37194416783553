import React from "react"
// ****************** Designed by Theewra. Developed by Explorelogy. ******************
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { StaticImage } from "gatsby-plugin-image"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import { graphql, Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import ReactPlayer from "react-player"

const BusinessProcessOptimisation = ({ data }) => {
  const images = {
    subHeader: convertToBgImage(getImage(data.subHeader)),
  }
  return (
    <Layout>
      <Seo title="Success stories | Redefining Travel" />
      <section>
        <div className="main">
          <BackgroundImage
            {...images.subHeader}
            className="sap-header sub-success"
          >
            <div className="container">
              <div className="sap-header-content">
                <div className="img">
                  <StaticImage
                    placeholder="blurred"
                    src="../../images/sap/clients/wkv.png"
                    width={300}
                    alt="techpac"
                  />
                </div>
                <div className="success-header-title">
                  Business Process Optimisation
                </div>
                <div className="header-description">
                  W.K.V HydroTechnic is Sri Lanka’s leading mini hydro power
                  producer, known for its innovative and sustainable energy
                  solutions.
                </div>
              </div>
            </div>
          </BackgroundImage>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="sub-success-content">
            <div className="row">
              <div className="col-md-6 sub-success-details">
                <p>
                  W.K.V HydroTechnic is a pioneering mini hydro power producer
                  in Sri Lanka, dedicated to sustainable energy solutions. In
                  2018, we had the privilege of implementing SAP Business
                  ByDesign for them. Our team successfully optimised their
                  business processes, delivering results with expertise,
                  professionalism, and promptness. We are proud to have exceeded
                  their expectations.
                </p>
                {/* <p>
                  Tech Pacific successfully implemented the SAP Business Suite
                  powered by SAP HANA for United Motors, including a
                  comprehensive, tailor-made automotive vertical. The SAP
                  solution uses in-memory computing technology to optimize
                  business processes. The Digital Transformation resulted in
                  speeding up and simplifying of processes across the company,
                  jump-starting real-time analytics and deepening insights into
                  company-wide data to enable a sharper competitive edge.
                </p> */}
                <ReactPlayer
                  controls={true}
                  url="https://youtu.be/U4UFaAP17hE"
                />
              </div>
              <div className="col-md-6 sub-success-points">
                <div className="sub-success-items">
                  <div className="row">
                    <div className="col-4 col-md-3 sub-success-item-title">
                      Client
                    </div>
                    <div className="col-1">:</div>
                    <div className="col-7 col-md-4">W.K.V HydroTechnic’s</div>
                  </div>
                  <div className="row">
                    <div className="col-4 col-md-3 sub-success-item-title">
                      SAP Solution
                    </div>
                    <div className="col-1">:</div>
                    <div className="col-7 col-md-4">SAP Business ByDesign</div>
                  </div>
                  <div className="row">
                    <div className="col-4 col-md-3 sub-success-item-title">
                      Mode
                    </div>
                    <div className="col-1">:</div>
                    <div className="col-7 col-md-4">-</div>
                  </div>
                  <div className="row">
                    <div className="col-4 col-md-3 sub-success-item-title">
                      Start date
                    </div>
                    <div className="col-1">:</div>
                    <div className="col-7 col-md-4">-</div>
                  </div>
                  <div className="row">
                    <div className="col-4 col-md-3 sub-success-item-title">
                      End date
                    </div>
                    <div className="col-1">:</div>
                    <div className="col-7 col-md-4">-</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="get-in-touch blue d-flex align-items-center justify-content-center">
          <div className="get-in-touch-content text-center container">
            <p>Get In Touch With Our Experts</p>
            <Link to="/contact">
              <button
                className="btn btn-primary blue-contact-footer"
                type="button"
              >
                Let's Get to Work
              </button>
            </Link>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query BusinessProcessOptimisation {
    subHeader: file(relativePath: { eq: "success-stories/subHeader.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`

export default BusinessProcessOptimisation
